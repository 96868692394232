import dayjs from 'dayjs';
export const environment = {
  apiBaseUrl: 'https://dashboard-bot.cgmotors.com.np',
  host: 'https://dashboard-bot.cgmotors.com.np',
  port: ':443',
  version: '/rest/v1/',
  isDev: true,
  secret: '21231231312313123',
  googleAnalyticsUrl:
    'https://googleanalytics.palmchatbot.com/rest/v1/analytics',
  title: 'CG Motors',
  startDate: dayjs().subtract(3, 'month').toISOString(),
  copyright: {
    name: 'CG Motors',
    logo: '/assets/brand/fourwheel.png',
    url: {
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: '',
      website: '',
    },
  },
  providers: [],
  cacheTime: 0, //value treat as second
};
